export function inRangeDate(start_date, end_date) {
  let d1 = start_date.split("/")
  let d2 = end_date.split("/")

  let from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0])
  let to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0])
  let check = new Date()

  return from <= check && check <= to
}
