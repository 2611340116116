import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Index = ({ width, height, title, isBgPrimary, img_url, radius }) => (
  <div
    style={{
      width: width,
      height: height,
    }}
    className={`d-flex justify-content-center align-items-center ${
      isBgPrimary ? "ads-container-vertical" : "ads-container-horizontal"
    }`}
  >
    {img_url ? (
      <img
        src={img_url}
        style={{ objectFit: "cover", width: "100%", height: "100%", borderRadius: radius?radius:"0" }}
      />
    ) : (
      <>
        <StaticImage
          src="../../../../../static/images/ic_logo_gakeslab.png"
          alt="logo"
          placeholder="blurred"
        />
        <div className="d-flex flex-column m-3">
          <span className={`title ${isBgPrimary && "m-0"}`}>{title}</span>
          <span className="size">{`${width} X ${height} PIXEL`}</span>
        </div>
      </>
    )}
  </div>
)

export default Index
