import React from "react"
import { Card } from "react-bootstrap"

const Index = ({ name, image, position, bidang }) => (
  <>
    <Card style={{ margin: 10, height: "100%" }}>
      <Card.Img
        variant="top"
        height={300}
        style={{
          objectFit: "cover",
          display: "block",
        }}
        src={`${
          image
            ? `${process.env.GATSBY_API_URL}/file/administrator/${image}`
            : "../../../../images/Pengurus_default-image.png"
        }`}
      />
      <Card.Body className="p-2">
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="d-flex flex-column h-100">
            <span
              style={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}
            >
              {name}
            </span>
            {/* <br />
            <span style={{ fontSize: 12 }}>{position}</span> */}
          </div>
          <span
            style={{
              fontSize: 14,
              color: "#00a650",
              padding: "5px 10px",
              borderRadius: 10,
              backgroundColor: "#eff0f2",
              textAlign: "center",
            }}
          >
            {bidang}
          </span>
        </div>
      </Card.Body>
    </Card>
  </>
)

export default Index
